import '../css/FadeInIntro.css'; // Create a CSS file for your styles

const FadeInIntro = () => {
  return (
    <div className="fade-in-container profile-info profile-info-text flex text-outline rajdhani-font">
      <p className="fade-in-item">Hi!</p>
      <p className="fade-in-item">I'm Nick.</p>
      <p className="fade-in-item">Full Stack Software Engineer</p>
    </div>
  );
}

export default FadeInIntro;