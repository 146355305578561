import { Project } from "./Project";
import { convertTags } from "./Tags";

// Set this to be an array of type Project?
export const projectData: Project[] = [
    {
      id: 0,
      overview: "URC/URC_1.png",
      images : ["URC/URC_1.png", "URC/URC_2.png", "URC/URC_3.png", "URC/URC_4.png", "URC/URC_5.png", "URC/URC_6.png", "URC/URC_7.png", "URC/URC_8.png", "URC/URC_9.png"],
      title: 'University Research Coordinator',
      tags: ["Bootstrap", "C#", "HTML5", "CSS3", ".Net", "MySQL", "Javascript", "Selenium", "JQuery", "Express", "Selenium"],
      description: ["For my CS 4540 - Web architecture course, I had used C#, ASP Core, and SQL to create a MVC full stack website. The purpose of this assignment was to create a website that would pair students seeking research opportunities with professors that could use help with their research. I started this assignment by writing HTML and CSS by hand. This was my first experience dealing with the front-end; and was a great learning experience and taught me a lot about the full stack, user roles, and hosting/managing a website).", "It didn’t take long to find out that bootstrap was a better way to orient a lot of the front-end elements. I also added a SQL back-end that was hosted on AWS server using IIS Express. This project progressed to the point where API endpoints and AJAX methods were included to create single page web applications that can query and modify the backend and display results to the view without having to refresh the page. This involved an AJAX method that would use elements passed in from the controller via a viewbag and then sending a JSON object to the API method. Lastly, I teamed up with a few other classmates and merged our work together to create a final product with even more features, such as a single page web application for messaging. (Complete with a customized HTML/CSS front-end) The final product also included some data analytics available for moderators of the web page to view, providing detailed information which can guide the direction of the site."],
      schoolAssignment: true,
    },    {
      id: 1,
      overview: "SpriteEditor/Capture.png",
      images : ["SpriteEditor/A7+-+Software+Requirements+Specification-1.png", "SpriteEditor/A7+-+Software+Requirements+Specification-2.png","SpriteEditor/A7+-+Software+Requirements+Specification-3.png","SpriteEditor/A7+-+Software+Requirements+Specification-4.png","SpriteEditor/A7+-+Software+Requirements+Specification-5.png","SpriteEditor/A7+-+Software+Requirements+Specification-6.png","SpriteEditor/A7+-+Software+Requirements+Specification-7.png", "SpriteEditor/Capture.png", "SpriteEditor/Managagement+plan-1.png", "SpriteEditor/UML+diagram-1.png"],
      title: 'Sprite Editor',
      tags: ["C++", "Qt"],
      description: ["As a school project in CS 3505 - Programming Practice II, myself and 5 other students grouped up to develop a sprite editor in C++ using the QT framework. There was an emphasis on developing using the waterfall software development model, which included a detailed UML diagram, software requirements specification, some independent market research, and a Gantt chart to plan and track our progress (which can be seen below). This assignment gave me plenty of experience with QT and it taught me how to coordinate with larger team sizes and how helpful it can be to have a drawn out plan for software."],
      schoolAssignment: true,
      videoURL: "https://www.youtube.com/embed/I33JTs9RCQU",
    },
    {
      id: 2,
      overview: "3DRayTracing/Raytracing_1.png",
      images : ["3DRayTracing/Raytracing_1.png"],
      title: '3D Raycasting',
      tags: ["HTML5", "Javascript", "OpenGL"],
      description: ["For a project within my CS 4600 Introduction to computer graphics class, I used openGL for GPU rendering of a 3D ray tracer. This program comes with a menu for toggles that allow for modifying sphere count, the number of bounces, and the type of rendering. It uses blinn/phong model for shading and lighting. This was one of my favorite projects to code because it tied many of the concepts I had learned from computer graphics together. There were many obstacles to overcome and I am pleased with the final result."],
      schoolAssignment: true,
      videoURL: "https://www.youtube.com/embed/M6RgrxK4How",
    },
    {
      id: 3,
      overview: "LMS/LMS_CROP.jpg",
      images : ["LMS/ER_Diagram_LMS.png","LMS/LMS_1.png","LMS/LMS_2.png","LMS/LMS_3.jpg","LMS/LMS_4.png","LMS/LMS_5.jpg",],
      title: 'LMS Database',
      tags: ["MySQL", "C#", ".Net", "HTML5", "CSS3", "Express"],
      description: ["As a final project for my CS 5530 - Databases class, I worked with a partner to create the back-end of a learning management system (LMS) database. Using MySQL (specifically MariaDB), we began by creating an ER diagram (following Chen notation) which we then converted into tables on our database. We then used scaffolding to import the layout of our database into C#, where we would use LINQ to simplify our operations. We also learned how to keep user information private and isolated from our database (by hashing the password and adding randomized salt and storing the result within a separate database). From here, the rest of the work was adding the SQL query for each action that the user could interact with on the web page. This was one of my favorite projects to date. It took a long time, from the planning phase to completion, and seeing the website live made it all worth it. I learned so much about SQL as well as LINQ and am excited to see how I can apply this knowledge to other applications."],
      schoolAssignment: true,
    },
    {
      id: 4,
      overview: "Blackjack/BlackJack_Capture.png",
      images : ["Blackjack/BlackJack_Capture.png"],
      title: 'Blackjack Tutorial',
      tags: ["Qt", "C++"],
      description: ["For the final project of CS 3505 - Software Practice II, I teamed up with the same (6-man) team from the sprite editor assignment, and we created another QT application, written in C++, with the purpose of being educational. We chose to teach our players how to count cards in blackjack! There was a focus on practicing Agile software development (specifically SCRUM). So we started this assignment off by building a backlog on GitHub, and we divided the work into 3 sprints, and then got to work! I’m really happy with our end product and it was fun to see our design evolve and change into a polished solution."],
      schoolAssignment: true,
      videoURL: "https://www.youtube.com/embed/MELdzarfWwE",
    },
    {
      id: 5,
      overview: "DayPlanner/DayPlanner1.png",
      images : ["DayPlanner/DayPlanner1.png","DayPlanner/DayPlanner2.png","DayPlanner/DayPlanner3.png"],
      title: 'Day Planner Mobile App',
      tags: ["Android", "iOS", "Django", "Python"],
      description: ["During the Summer of 2019, I wanted to test my knowledge of python as well as try developing an application for mobile devices. Using the kivy library, I developed my first mobile, multi-touch user interface! I added my own spin on the average day planner by first letting you create the activities you wanted to achieve throughout the day, and using touch to reposition your activities, double tap to remove your activity, and multi-touch to resize your activity. I learned a lot about kivy’s nesting layouts, dynamically resizing widgets, the clarity of code that kv files provide, and strengthened my understanding of python."],
      schoolAssignment: false,
    },
    {
      id: 6,
      overview: "TextureMapping/TextureMapping_1.png",
      images : ["TextureMapping/TextureMapping_1.png"],
      title: 'Texture Mapping',
      tags: ["OpenGL", "Javascript", "HTML5"],
      description: ["For my 4th project in CS 4600 - Computer graphics, I learned how to use WebGL to apply 2D texture mapping to 3D models. This project was my first attempt at using the vertex and fragment shader to utilize the GPU with rendering models. This project was the basis of which the later physics simulation assignment was built upon."],
      schoolAssignment: true,
      videoURL: "https://www.youtube.com/embed/NOXXDQHce4Y",
    },
    {
      id: 7,
      overview: "Portfolio/Portfolio.PNG",
      images : ["Portfolio/Portfolio.PNG"],
      title: 'Coding Portfolio',
      tags: ["AWS", "Bootstrap", "CSS3", "Docker", "HTML5", "Javascript", "Jest", "React", "Typescript"],
      description: ["The website you are reading this on is also a personal coding project! For my first couple years of programming, I had a squarespace website to showcase my projects, but as my experience with the frontend and various frameworks expanded, I thought that creating my own website from a basic react app is a great way to show my growth! The languages and frameworks I used for this project were: React for the frontend, Jest for the unit tests, gitlab for issue tracking and version control, Docker for deploying containers, and various AWS tools for cloud hosting (Route 53, EC2, and ECR)."],
      schoolAssignment: false,
    },
    {
      id: 8,
      overview: "Techcyte/Techcyte_Logo.jpg",
      images : ["Techcyte/Techcyte_Logo.jpg"],
      title: 'Techcyte',
      tags: ["AWS", "Android", "iOS", "CSS3", "Dart", "Docker", "Flutter", "Go", "GraphQL", "NodeJS", "HTML5", "Javascript", "Jest", "React", "Terraform", "Typescript"],
      description: ["Within the years I have worked at Techcyte, I have had the pleasure to collaborate and learn with a handful of different teams. A summary of these teams and some of my contributions are detailed within my resume."],      
      schoolAssignment: false,
    },
    {
      id: 9,
      overview: "ChessBrowser/Chess_Browser_2.png",
      images : ["ChessBrowser/Chess_Browser_1.png","ChessBrowser/Chess_Browser_2.png","ChessBrowser/Chess_Browser_3.png","ChessBrowser/Chess_Browser_4.png","ChessBrowser/Chess_Browser_5.png","ChessBrowser/Chess_Browser_6.png"],
      title: 'Chess Browser',
      tags: ["MySQL", ".Net", "C#"],
      description: ["As an assignment for my CS5530 - Databases class, I implemented the back-end of a chess search Browser. To use the application, you begin by uploading a file with the extension of PGN, which follows a JSON format, holding data for thousands of games. The progress bar will fill once the information about every player, game, and event  has been extracted and added to the respective table. Now we can filter games by a variety of properties and dynamically query the database without having to worry about any of the table details. It taught me some of the basics about dynamic queries, as well as how to prevent SQL injection attacks by sanitizing my inputs. This was my first introduction to some of the challenges of writing efficient queries and I learned a lot."],
      schoolAssignment: true,
    },
    {
      id: 10,
      overview: "Encryption/encryption_1 (1).png",
      images : ["Encryption/encryption_1 (1).png","Encryption/encryption_2.png","Encryption/encryption_3.png","Encryption/encryption_4.png", "Encryption/Encryption_1.png"],
      title: 'Secure Messaging',
      tags: ["Python"],
      description: ["For CS 4480 - Computer Networks, I implemented a secure messaging program that sends encrypted messages between two separate hosts using my own application layer protocol. The exchange is a bit complicated to summarize, but it utilizes public and private keys, a certificate authority, SHA1 hashing, RSA encryption, and a symmetric key, all sent over using a TCP connection. (The diagram below shows the process of packaging a secure outgoing message, from Alice (the sender) to Bob (the receiver). The same process is performed in reverse order to then read and verify the message!"],
      schoolAssignment: true,
    },
    {
      id: 11,
      overview: "PhysicsSim/PhysicsSim_1.png",
      images : ["PhysicsSim/PhysicsSim_1.png"],
      title: '3D Physics Simulation',
      tags: ["OpenGL", "Javascript", "HTML5"],
      description: ["For the final project for my CS 4600 - Introduction to computer graphics class, I modified my CS 4600 - A4 project to incorporate physics. It renders low-polygon models that can be interacted by dragging it’s vertices in 3D space. There are several modifiers that affect the movement of the object (all of which can be seen in the video)."],
      schoolAssignment: true,
      videoURL: "https://www.youtube.com/embed/VR63C3-TXE8",
    },
    {
      id: 12,
      overview: "Spreadsheet/screenshot1.jpg",
      images : ["Spreadsheet/screenshot1.jpg"],
      title: 'Spreadsheet',
      tags: ["C#", ".Net"],
      description: ["This was a school assignment from CS 3500 - Software Practice I. This was a project that revealed how important it is to keep code clean and encapsulated. I began by implementing a formula class that reads a string, adding operators and operands to separate stacks, and solves mathematical equations. Combined with a spreadsheet GUI and underlying dictionary as the data structure, I learned how to view problems abstractly (think about dependencies as an ordered pair) and how to organize several projects together into a final solution."],
      schoolAssignment: true,
    },
    {
      id: 13,
      overview: "Boggle/BoggleClient+Screenshot.png",
      images : ["Boggle/BoggleClient+Screenshot.png"],
      title: 'Boggle Service',
      tags: ["MySQL", "Express", "C#", ".Net"],
      description: ["This program was a school project for CS 3500 - Software Practice I. Using a provided boggle board class API, my CS partner and I implemented a client, server, and SQL database for this assignment.", "We were given a lot of creative freedom to design our own client’s GUI.  After filling out your name and desired game time limit, a HTTP request would be sent to our server. The Boggle Service Controller would then pull out the relevant information (from parameters and URI) to create objects that would be added into our SQL database. To finish off the assignment, we uploaded the assignment to the cloud via azure."],
      schoolAssignment: true,
    },
    {
      id: 14,
      overview: "EcoSim/EcoSim+1.png",
      images : ["EcoSim/EcoSim+1.png","EcoSim/EcoSim+2.png","EcoSim/EcoSim+3.png","EcoSim/EcoSim+4.png",],
      title: 'Ecology Simulation',
      tags: ["Java"],
      description: ["This was a school assignment from CS 1410 - Object Oriented Programming. This ecology simulator was one of the bigger projects in the course, and taught me how developing one feature in a program can help build another. We started the assignment by creating an organism interface (with abstract methods like reproduce and eat) and provided rules for how a plant, herbivore, and carnivore would behave. Then we used a pixel editor to create a mini-map of our own design (shown on the right edge of each picture). We would then scan each pixel from the mini-map and associate a detailed image of terrain with each color. Combining all of these tasks into one project yielded a thriving ecology simulator with dials to tweak how many of each organism the sim begins with."],
      schoolAssignment: true,
    },
    {
      id: 15,
      overview: "2DRayCasting/RayCasting1.jpg",
      images : ["2DRayCasting/RayCasting1.jpg","2DRayCasting/Raycasting2.jpg","2DRayCasting/Raycasting3.jpg","2DRayCasting/Raycasting4.jpg",],
      title: '2D Raycasting',
      tags: ["Python"],
      description: ["As a personal project, I set out to challenge myself and learn some of the features of python by creating a 2D pygame. As my first python program, I learned a lot about the syntax of python, as well as the freedom that it gives the developer. I had a lot of fun making this program and adding new features as I went along. This program reads in coordinates from a text file which then builds the obstacles onto the plane. You are free to move your circular, yellow character around any obstacles and the line of sight will interact with the walls as you move. The character’s field of vision follows and rotates along with the mouse position."],
      schoolAssignment: false,
    },
    {
      id: 16,
      overview: "Esri/Esri.png",
      images : ["Esri/Esri.png", "Esri/UI.png"],
      title: 'ESRI Demographic Tool',
      tags: ["MongoDB", "C#", "Java"],
      description: ["I participated in my first Hackathon, “Hack the U” over fall break in 2019. A few friends and I teamed up together to build a visual tool that would help companies learn more about their consumer demographics. Using Esri’s SDK and MongoDB atlas, we created a program, when given customer information (such as address, age, etc) via JSON file, would display an icon at the location. We also developed a simple experimental user interface that allows for filtering. This means if your company is trying to decide the best place to build a physical store, you can see all customers that fit within a certain demographic, which will allow you to make well informed decisions for expansion. We used Java to generate the spreadsheet of random user data and C# for the Esri SDK."],
      schoolAssignment: false,
    },
    {
      id: 17,
      overview: "PhysicsApp/Slingshot+Screenshot.png",
      images : ["PhysicsApp/Slingshot+Screenshot.png","PhysicsApp/Tessellate+Screenshot.jpg", "PhysicsApp/Wave_Merge.png"],
      title: '2D Physics Simulation',
      tags: ["C#", ".Net"],
      description: ["During the Summer of 2019, after taking Physics 2210, I wanted to try modeling some of the concepts I had learned, through software. Using MDI forms, I created a handful of small applications that visualized basic kinetic motion, merging of various waves, inelastic and elastic collisions, and more!"],
      schoolAssignment: false,
    },
    {
      id: 18,
      overview: "TowerDefense/Tower+Defense+1.png",
      images : ["TowerDefense/Tower+Defense+1.png", "TowerDefense/Tower+Defense+2.png", "TowerDefense/Tower+Defense+3.png"],
      title: 'Tower Defense Game',
      tags: ["Java"],
      description: ["This was a school assignment for CS 1410 - Object oriented programming. While the code for this project was relatively simple, it was one of the first times I grasped many of the OOP principles. It was interesting to create a turret and enemy interface that defined the characteristics and properties for each object and to then create two classes that implemented those interfaces. I experimented with inheritance firsthand by using a data structure to hold Enemy objects, which would contain both enemy objects (snail and escargo)."],
      schoolAssignment: true,
    },
    {
      id: 19,
      overview: "SortingAlgo/Sorting_algo_1.png",
      images : ["SortingAlgo/Sorting_algo_1.png", "SortingAlgo/Sorting_algo_2.png"],
      title: 'Sorting Algorithm Visualizer',
      tags: ["Python"], // MatplotLib
      description: ["This program visualizes a handful of sorting algorithms of small arrays and graphs the run-time complexity for the best, average, and worst case. It uses pygame for the UI and Matplotlib for the easy building and display of graphs. Over the Summer, I wanted to brush up on my sorting algorithms as well as automate the process of building arrays and displaying their complexity. While the UI isn’t easy on the eyes, it does the job."],
      schoolAssignment: false,
    },
    {
      id: 20,
      overview: "FlightPath/FlightPath.jpg",
      images : ["FlightPath/FlightPath.jpg"],
      title: 'Flight Path',
      tags: ["Java"],
      description: ["This was the final project in my CS 2420 class. Using dijkstra’s algorithm, we created a program that would read flight information from a spreadsheet, created a weighted graph with nodes as airports and edges as the flight between them. We would then calculate the average distance, cost, time, and delay, so that you could find the best flight path for you! This was one of the more difficult and rewarding assignments I have created."],
      schoolAssignment: true,
    },
    {
      id: 21,
      overview: "PathFinder/Maze1.jpg",
      images : ["PathFinder/Maze1.jpg", "PathFinder/Maze2.jpg"],
      title: 'Path Finder',
      tags: ["Java"],
      description: ["This was an early CS 2420 assignment that got us thinking about graphs and breadth first search. It was a simple program, but fun because it felt dynamic and provided visual feedback. You begin by providing a width and height on the first line of a text file. Then it would scan each line and parse each ‘X’ as a wall, spaces as a floor tile, ‘S’ for the starting point, and ‘G’ for the goal. Then the shortest path was calculated and the modified text document was returned, showing the shortest path to the goal."],
      schoolAssignment: true,
    },
    {
      id: 22,
      overview: "WIP/WIP.png",
      images : ["WIP/WIP.png"],
      title: 'In Progress',
      tags: [],
      description: ["I'm always working on new coding projects to pursue my interests, refine my coding skills, and explore new frameworks and technologies. Check out my Github profile and see what I have in progress!"],
      schoolAssignment: false,
    },
    // {
    //   id: 20,
    //   overview: "TwitchClips.png",
    //   images : ["TwitchClips/TwitchClips.png"],
    //   title: 'Twitch Clip Web Scraper',
    //   tags: [],
    //   description: ["I'm always working on new coding projects to pursue my interests, refine my coding skills, and explore new frameworks and technologies. Check out my Github profile and see what I have in progress!"],
    //   schoolAssignment: false,
    // },
  ];

  export const getProjectData = (id : number) : Project => {
    const selectedProject: Project = {
        id: projectData[id].id ?? -1,
        overview: projectData[id].overview ?? 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        images: projectData[id].images ?? ['https://images.unsplash.com/photo-1471357674240-e1a485acb3e1', 'https://images.unsplash.com/photo-1567306301408-9b74779a11af'],
        title: projectData[id].title ?? "NO TITLE FOUND",
        tags: convertTags(projectData[id].tags) ?? ["ERROR"],
        description: projectData[id].description ?? ["NO DESCRIPTION FOUND"],
        schoolAssignment: projectData[id].schoolAssignment ?? true,
        videoURL: projectData[id].videoURL ?? undefined,
    };
    return selectedProject;
  }


  export const schoolWarning = "Due to this program being a school assignment, the code is within a private github repository. If you are interested in seeing the code, feel free to email me and I will provide temporary viewing access."