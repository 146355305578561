export type Tag = "AWS" | "Android" | "iOS" | "Bootstrap" | "C++" | "C#" | "CSS3"
| "Dart" | "Docker" | "Django" | ".Net" | "Express" | "Flutter" | "GCC" | "Go" | "GraphQL" |
"HTML5" | "Java" | "Javascript" | "Jest" | "JQuery" | "MongoDB" | "MySQL" | "NodeJS" 
| "OpenGL" | "Python" | "Qt" | "React" | "Selenium" | "Terraform" | "Typescript" | "Undefined" 


export const convertTags = (tags: string[]): Tag[] => {
    return tags.map((tag) => tag as Tag).filter((tag) => Boolean(tag));
  };

// AJAX
// ASP CORE
// DOCKER
// Git / Github / Gitlab
// - Fastlane
// - Codemagic

// Concepts as tags
// Computer Graphics
// Database
// Networks
// DevOps
// etc


// Frameworks to consider removing
// Terraform
// Express
