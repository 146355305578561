
import '../css/AboutMe.css'

export default function AboutMe() {

    return (
        <>
        <div className='column-container-bio'>
            <div className='contact-bio rajdhani-font bio-info-text'>
                Hi there, my name is Nick, and I'm a software developer at Techcyte. I graduated from the University of Utah in 2021 with a Bachelor's degree in Computer Science and a minor in Mathematics.
                <br />
                <br />
                During my last year of school, I interned at Techcyte and have been working there full-time ever since. As part of the team, I've had the opportunity to work on some exciting projects that have helped me grow as a developer and gain a deeper understanding of the industry. Whether it's developing a new feature for our software or collaborating with colleagues on a complex problem, each project has taught me valuable lessons that I've been able to apply to future work.
                <br />
                <br />
                When I'm not working, I love to travel, play video games and board games, hike, play tennis, and ski. My favorite food is sushi, and I can never start my day without a cup of coffee.
                <br />
                <br />
                I'm a firm believer in the power of collaboration and enjoy working with others to solve complex problems. I strive to bring a positive attitude to work every day and am always looking for ways to help my team succeed.
                <br />
                <br />
                If you have any questions or want to learn more about my experience, please don't hesitate to reach out. I look forward to connecting with you!
                <br />
                <br />
            </div>
        </div>
        </>
    );
}
