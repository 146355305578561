import { Button } from '@mui/material';
import '../css/Resume.css'

export default function Resume() {

    return (
        <>
        <div className='resume-container'>
            <Button className='resume-download' variant="outlined"> 
                <a className="resume-download-text" href={require("../assets/resume/Resume.pdf")} download="Nicholas Nord Resume 2025">Download Resume</a>
            </Button>
            <div className='resume-flex-container'>
                <iframe className='resume' src="https://docs.google.com/document/d/e/2PACX-1vQhyGu-pkO9cjr4JawWZaN6zLp4HUL0zLmhVjuRbwDxMlfkGwbuu-oa5YQmGIrba7783_wZXOmYNQ_A/pub?embedded=true" title='Resume'></iframe>
            </div>
        </div>
        </>
    );
}