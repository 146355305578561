
import { Dialog, DialogContent, DialogActions} from '@material-ui/core';
import { Button } from 'react-bootstrap';
import '../css/Resume.css'
import React from 'react';

// interface PopupProps {
//     image?: string;
//     display: boolean;
// }
// export default function Popup({image, display}: PopupProps) {


export default function Popup({children, image}: any) {
    const [open, setOpen] = React.useState(false);
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
      };
  

    return (
        <>
        <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        >
        <DialogContent className='popup-dialog'>
            <img className='popup-fullscreen' src={require(`../assets/projects/${image}`)} alt="resume" />
        </DialogContent>
        <DialogActions>
            <Button variant="outline" onClick={handleClose}>Close</Button>
        </DialogActions>
        </Dialog>
        <div onClick={handleOpen}>
            {children}
        </div>
        </>

    );
}
