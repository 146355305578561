import './css/App.css'
import {useRef, useState } from 'react';
import {Avatar, AppBar } from '@material-ui/core'; //importing material ui component
import { FrameworkFilterToggle } from './components/FrameworkFilterToggle';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@mui/material';
import PortfolioShowcase from './components/PortfolioShowcase';
import { Tag, convertTags } from './data/Tags';
import Resume from './components/Resume';
import AboutMe from './components/AboutMe';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FadeInIntro from './components/FadeInIntro';

function App() {
  const introRef = useRef<null | HTMLDivElement>(null); 
  const portfolioRef = useRef<null | HTMLDivElement>(null); 
  const resumeRef = useRef<null | HTMLDivElement>(null); 
  const bioRef = useRef<null | HTMLDivElement>(null); 
  const [activeFilters, setActiveFilters] = useState(['']);

  const handleActiveFilterChange = (activeFilters: Tag[]) => {
    setActiveFilters(activeFilters);
  };

  return (
  <>
    <div className="root-div">
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rajdhani" />
        <AppBar>
          <div role="navigation" className='appbar'>
            <Button className='navbar-option' variant="text" onClick={() => introRef.current?.scrollIntoView({behavior: 'smooth'})}>Intro</Button>
            <Button className='navbar-option' variant="text" onClick={() => portfolioRef.current?.scrollIntoView({behavior: 'smooth'})}>Portfolio</Button>
            <Button className='navbar-option' variant="text" onClick={() => resumeRef.current?.scrollIntoView({behavior: 'smooth'})}>Resume</Button>
            <Button className='navbar-option' variant="text" onClick={() => bioRef.current?.scrollIntoView({behavior: 'smooth'})}>About</Button>
          </div>
        </AppBar >
      </div>
      <div ref={introRef} className="column-container base">
        <div className="profile-picture flex">
          <div className='picture-frame'>
            <div className='profile-picture overlap' >
              <Avatar 
                alt="Nicholas Nord"
                src={require('./assets/style/japan_pic.png')}
                style={{ width: '70%', height: '70%' }}
              />
            </div>
          </div>
          <br />
          <div className='contact-logos'>
            <Tooltip title="(801) 971-7336" arrow>
              <PhoneIcon style={{ color: 'white', fontSize: 38 }} onClick={() => window.location.href = 'tel:+18019717336'}/>
            </Tooltip>
            <Tooltip title="nicholasnord@gmail.com" arrow>
              <EmailIcon style={{ color: 'white', fontSize: 40 }} onClick={() => window.location.href = 'mailto:nicholasnord@gmail.com'}/>
            </Tooltip>
            <Tooltip title="Linkedin" arrow>
              <LinkedInIcon style={{ color: 'white', fontSize: 38 }} onClick={() => window.location.href = 'https://www.linkedin.com/in/nicholas-nord-644220194/'} />
            </Tooltip>
            <Tooltip title="Github" arrow>
              <GitHubIcon style={{ color: 'white', fontSize: 35 }} onClick={() => window.location.href = 'https://github.com/Nordicade'}/>
            </Tooltip>
          </div>
        </div>
        <FadeInIntro />
      </div>
      <div ref={portfolioRef} className="subheader-label rajdhani-font text-outline">
        PORTFOLIO
      </div>
      <div className='portfolio'>
        <div className="framework-filter" >
          <FrameworkFilterToggle onActiveFilterChange={handleActiveFilterChange}/>
        </div>
        <div className='portfolio-showcase-container'>
          <PortfolioShowcase activeFilters={convertTags(activeFilters)}/>
        </div>
      </div>
      <div ref={resumeRef} className="subheader-label rajdhani-font text-outline">
          RESUME
      </div>
      <div className="column-container-resume">
      <Resume />
      </div>
      <div ref={bioRef} className="subheader-label rajdhani-font text-outline">
          ABOUT ME
      </div>
      <AboutMe />
  </>
  );
}

export default App;
