import { Box, IconButton } from '@material-ui/core';
import '../css/ProjectDetails.css'
import '../data/PortfolioData'
import { getProjectData } from '../data/PortfolioData';
import CloseIcon from '@material-ui/icons/Close';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Project } from '../data/Project';
import Popup from './Popup';

export default function ProjectDetails(props: any)  {
    const {id} = props;
    const projectDetails = getProjectData(id);
    

    const onClose = () => {
        props.stateChanger(-1)
        props.portfolioRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'})
    };


    const onlyOnePicture = projectDetails.images.length === 1;

    // No video? 
        // A) Only one picture?
            // 1) MyPicture with description
        // B) Many pictures?
            // 1) MyCarousel with description
    
    // Video
        // A) Only one picture?
            // Just myVideo
        // B) Many Pictures?
            // myVideo with MyCarousel without description

    return (
        <>
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
            integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
            crossOrigin="anonymous"
            />
            <Box className="project-details-container" key={projectDetails.id}>
                <Box className="project-details-title-container">
                    <Box className="project-details-title">
                        <b>{projectDetails.title}</b>
                    </Box>
                    <Box>
                        <IconButton className="close-button" onClick={() => onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                {!projectDetails?.videoURL && onlyOnePicture && 
                    <MyPicture projectDetails={projectDetails}/>
                }
                {!projectDetails?.videoURL && !onlyOnePicture && 
                    <MyCarousel projectDetails={projectDetails} includeDescription={true}/>
                }
                {projectDetails.videoURL && onlyOnePicture && 
                    <MyVideo projectDetails={projectDetails}/>
                }
                {projectDetails.videoURL && !onlyOnePicture && 
                    <>
                        <MyVideo projectDetails={projectDetails}/>
                        <MyCarousel projectDetails={projectDetails} includeDescription={false}/>
                    </>
                }
            </Box></>
    );
}

const MyVideo = (props: { projectDetails: Project; }) => {
    const projectDetails = props.projectDetails;
    return <>
            <Box>
            <iframe className="project-details-video" src={projectDetails.videoURL} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" />
            <MyDescription projectDetails={props.projectDetails} />
        </Box>
        </>
};

const MyCarousel = (props: { projectDetails: Project, includeDescription: boolean}) => {
    const carouselClass = props.includeDescription ? "project-details-primary-carousel" : "project-details-secondary-carousel"
    return <>
            <Box >
            {/* Carousel goes here (floated to the LHS corner) */}
            <Carousel defaultActiveIndex={0} fade className={carouselClass} variant="dark" key={ProjectDetails.name}>
                {props.projectDetails.images.map((image) => (
                    <Carousel.Item interval={3000}>
                        
                        <Popup image={image}>
                            <img
                            className="d-block w-100 force-square-sized"
                            src={require(`../assets/projects/${image}`)}
                            alt={`${props.projectDetails.title} - ${image}`}
                            />
                        </Popup>
                    </Carousel.Item>
            ))}
            </Carousel>
            {props.includeDescription && 
                <MyDescription projectDetails={props.projectDetails} />
            }
        </Box>
        </>
};


const MyPicture = (props: { projectDetails: Project}) => {

    return <>
            <Box >
            <Box className="project-details-picture" >
                {props.projectDetails.images.map((image) => (
                    <>
                    <Popup image={image}>
                        <img
                        className="d-block w-100 force-square-sized"
                        src={require(`../assets/projects/${image}`)}
                        alt={`${props.projectDetails.title} - ${image}`}
                        />
                    </Popup>
                </>
            ))}
            </Box>
            <Box className='project-details-description'>
                <MyDescription projectDetails={props.projectDetails} />
            </Box>
        </Box>
        </>
};

const MyDescription = (props: {projectDetails: Project}) => {
    const schoolProjectLabel = "Due to this program being a school assignment, the code is within a private github repository. If you are interested in seeing the code, feel free to email me and I will provide temporary viewing access.";
    const description = props.projectDetails.description.slice();
    if(props.projectDetails.schoolAssignment) {
        description.push(schoolProjectLabel)
    }

    if(description.length <= 1) {
        return (
            <Box className='project-details-description'>
                {description}
            </Box>
        );
    }
    return (
         <Box className='project-details-description'>
        {
        description.map((paragraph) => (
            <>
            {paragraph}
            <br />
            <br />
            </>
        ))}   
    </Box>
    )
}