import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import '../css/FrameworkFilterToggle.css'
import {  Collapse, Zoom } from '@material-ui/core';
import { useEffect, useState } from 'react';
import {  KeyboardArrowDownRounded, KeyboardArrowRightRounded} from '@material-ui/icons';
import { Tag, convertTags } from '../data/Tags';
import toggleButtonData from '../assets/frameworks/frameworks.json'

interface FrameworkFiltersProps {
    onActiveFilterChange: (updatedActiveFilters: Tag[]) => void;
    showFilters: boolean;
}

 const FrameworkFilters = ({onActiveFilterChange, showFilters }: FrameworkFiltersProps) => {
    const [formats, setFormats] = React.useState(() => ['']);
    const handleFormat = (
      event: React.MouseEvent<HTMLElement>,
      newFormats: string[],
    ) => {
      setFormats(newFormats);

      onActiveFilterChange(convertTags(newFormats));
    };

    const [buttonsPerGroup, setButtonsPerGroup] = useState(calculateButtonsPerGroup());

    useEffect(() => {
      const handleResize = () => {
        setButtonsPerGroup(calculateButtonsPerGroup());
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const groups = [];
    const totalButtons = toggleButtonData.length;
  
    for (let i = 0; i < totalButtons; i += buttonsPerGroup) {
        const buttons = toggleButtonData.slice(i, i + buttonsPerGroup).map((button) => (
            <ToggleButton className="portfolio-filter-icon" value={button.Name} aria-label={button.Name} key={button.Name}>
            <Zoom in={showFilters}>
                <Tooltip title={button.Name} arrow>
                    <img alt={button.Name} src={button.Src} />
                </Tooltip>
            </Zoom>
        </ToggleButton>
        ));
  
        groups.push(
            <ToggleButtonGroup
            className='filter-container'
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
            key={i}
            >
           {buttons}
          </ToggleButtonGroup>
        );
      }

    return (
      <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css"/>
        {groups}
      </>
    );
  }

interface FrameworkFiltersToggleProps {
    onActiveFilterChange: (updatedActiveFilters: Tag[]) => void;
}
  
// export default function FrameworkFilterToggle({onActiveFilterChange }: FrameworkFiltersProps) {
export const FrameworkFilterToggle = ({onActiveFilterChange }: FrameworkFiltersToggleProps) => {
    const [showFilters, setShowFilters] = useState(window.innerWidth >= 375);
    const [activeFilters, setActiveFilters] = useState(['']);

    const handleActiveFilterChange = (activeFilters: Tag[]) => {
      setActiveFilters(activeFilters);
      onActiveFilterChange(activeFilters);

    };

    return (
        <>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css"/>
        <ToggleButton sx={{color: '#1976D5'}} value="check" onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? "Hide Project Filters" : "Show Project Filters" }
            {showFilters ? <KeyboardArrowDownRounded /> : <KeyboardArrowRightRounded /> }
        </ToggleButton>
            <Collapse in={showFilters}>
                <div className="framework-filter" >
                    <FrameworkFilters onActiveFilterChange={handleActiveFilterChange} showFilters={showFilters} />
                </div>
            </Collapse>
        </>
    );
}

const calculateButtonsPerGroup = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 200) {
        return 1; 
    } else if (screenWidth <= 295) {
        return 2;
    } else if (screenWidth <= 385) {
        return 3; 
    } else if (screenWidth <= 475) {
        return 4; 
    } else if (screenWidth <= 575) {
        return 5;
    } else if (screenWidth <= 750) {
        return 6; 
    } else if (screenWidth <= 850) {
        return 7; 
    } else if (screenWidth <= 950) {
        return 8; 
    } else if (screenWidth <= 1050) {
        return 9; 
    } else {
        return 10;
    }
  };