import '../css/PortfolioShowcase.css'
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { projectData } from '../data/PortfolioData'
import { useEffect, useRef, useState } from 'react';
import ProjectDetails from './ProjectDetails';
import { Box } from '@material-ui/core';
import { Tag } from '../data/Tags';
import { Project } from '../data/Project';
import { Alert } from '@mui/material';

interface PortfolioShowcaseProps {
    activeFilters: Tag[];
}

export default function PortfolioShowcase({activeFilters}: PortfolioShowcaseProps) {
    const [selectedProjectId, setSelectedProjectId] = useState(-1);
    const [filteredProjectCount, setFilteredProjectCount] = useState(projectData.length);

    const detailsRef = useRef<null | HTMLDivElement>(null); 
    const portfolioRef = useRef<null | HTMLDivElement>(null); 
    const updateSelectedProjectId = (id : number) => {
        setSelectedProjectId(id)
        detailsRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'})
    }

    useEffect(() => {
        const filteredProjects = filterProjectsByTags(projectData, activeFilters);
        setFilteredProjectCount(filteredProjects.length);
    }, [activeFilters]);

    function filterProjectsByTags(projects: Project[], tags: Tag[]): Project[] {
        var filteredProjects: Project[] =  projects.filter((project) => {
          return tags.every((tag) => project.tags.includes(tag));
        });
        return filteredProjects;
      }

    const NoProjectsFoundMessage = () => {
        return <Alert variant="outlined" severity="info">
            No projects were found that matched the selected filters.
        </Alert>;
    }

    return (
        <>
        <div ref={portfolioRef} className="portfolio-showcase">
            <Box className="portfolio-showcase-container">
                {filterProjectsByTags(projectData, activeFilters).map((project) => (
                <ImageListItem className="portfolio-thumbnail" key={project.overview} onClick={() => updateSelectedProjectId(project.id ?? -1)} >
                    <div className='rect-img-container'>
                        <img className="rect-img"
                        src={require(`../assets/projects/${project.overview}`)}
                        alt={project.title}
                        loading="lazy"
                        />
                    </div>
                    <ImageListItemBar sx={{fontFamily:"Rajdhani"}} 
                    title={project.title}
                    position="below"
                    />
                </ImageListItem>
                ))}
                {filteredProjectCount === 0 && <NoProjectsFoundMessage />}
            </Box>
        </div>
        <div ref={detailsRef} className="project-details">
            {selectedProjectId !== -1 && <ProjectDetails id={selectedProjectId} stateChanger={updateSelectedProjectId} portfolioRef={portfolioRef}/>}
        </div>
        </>
    );
}
